import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import PhoenixBranding from "branding-asset-primitives/phoenix/PhoenixHRALogo.png"
import HCLABranding from "branding-asset-primitives/hcla/logo-thick-xl.png"
import CoviDeSBranding from "../../assets/covides-icon-512px.png"
import CollapseAppCard from "./CollapseAppCard"
import { GridItem } from "./GridItems"
import Apps from "./Apps"
import PCATLOGO from "../../assets/PCATLogo.png"
import MOPRALOGO from "../../assets/mopraLogo.png"

const styles = () => ({
  // You can add any custom styles here if needed
})

const LevelOneApps = props => {
  return (
    <Apps>
      {process.env.INFECTION_PREDICTION_URL && (
        <GridItem link={process.env.INFECTION_PREDICTION_URL}>
          <CollapseAppCard
            title="CoviDeS"
            subheader="COVID-19 Decision Support Tool"
            description="Simulation tool for exploring COVID-19 scenarios and
            outcomes for a given community as a function of mitigation measures and policies.
            Utilizes a hybrid model covering dynamic interactions of epidemiological,
            psychological, demographic, policy and factors."
            image={CoviDeSBranding}
          />
        </GridItem>
      )}

      {process.env.PHOENIX_URL && (
        <GridItem link={process.env.PHOENIX_URL}>
          <CollapseAppCard
            title="Phoenix"
            subheader="Human Reliability Assessment Tool"
            description="Phoenix HRA is used to understand and assess the effect of human behavior on system risk,
                thereby incorporating this into PRA with the overall goals of assessing and reducing the likelihood and
                consequences of errors made by humans."
            image={PhoenixBranding}
          />
        </GridItem>
      )}

      {process.env.HCLA_URL && (
        <GridItem link={process.env.HCLA_URL}>
          <CollapseAppCard
            title="HCLA"
            subheader="Hybrid Causal Logic Analyzer - PRA Tool"
            description="HCLA is an application used to design the system architecture and perform
                traditional probabilistic risk assessment (PRA) with time-to-failure models, importance measures, and
                uncertainty quantification."
            image={HCLABranding}
          />
        </GridItem>
      )}

      {process.env.PCAT_URL && (
        <GridItem link={process.env.PCAT_URL}>
          <CollapseAppCard
            title="PCAT"
            subheader="Pipeline Corrosion Analysis Tool"
            description="PCAT (Pipeline
              Corrosion Analysis
              Tool)
              App to quantify internal corrosion, external corrosion, and SCC of gas pipelines. This app also provides the probability of failure of a pipeline at a segment level and system level over time."
            image={PCATLOGO}
          />
        </GridItem>
      )}

      {process.env.MOPRA_URL && (
        <GridItem link={process.env.MOPRA_URL}>
          <CollapseAppCard
            title="MoPRA"
            subheader="Mobility PRA"
            description="The MoPRA application is a traditional scenario-based probabilistic risk assessment platform to analyze the safety of different mobility systems with importance measures and uncertainty quantification capabilities. Users can assess the impact of different contextual elements (weather, road conditions, driver conditions), level of driving automation, and vehicle-to-everything communications on the probability of driving related incidents."
            image={MOPRALOGO}
          />
        </GridItem>
      )}
    </Apps>
  )
}

LevelOneApps.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LevelOneApps)
